<template>
  <div>
    <div class="d-flex ">
      <b-col md="3">
        <b-form-group label="Année universitaire">
          <v-select
            v-model="academicYear"
            :clearable="false"
            label="academic_year"
            placeholder="Année universitaire"
            :options="academicYears"
            disabled
          />

        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Groups">
          <v-select
            v-model="group"
            :clearable="false"
            placeholder="group"
            label="group_code"
            :options="groups"
            @input="getNotes"
          />

        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Subject">
          <v-select
            v-model="subject"
            :clearable="false"
            placeholder="subject"
            label="subject"
            :options="subject_groups"
            @input="deleteSemester"
          />

        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Semester">
          <v-select
            v-model="semester"
            :clearable="false"
            placeholder="Semester"
            :options="semesters"
            @input="getListeNotes"
          />

        </b-form-group>
      </b-col>

      <!-- <b-col md="3">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </b-col> -->

    </div>
    <vue-good-table
      :columns="columns"
      :rows="notezero"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'username'"
          class="text-nowrap"
        >
          <b-avatar
            src="/assets/images/avatars/user.png"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.username }}</span>
        </div>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'subject.assiduity'">
          <b-badge variant="primary">
            {{ props.row.subject.assiduity }}%
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'subject.ds'">
          <b-badge variant="primary">
            {{ props.row.subject.ds }}%
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'subject.exam'">
          <b-badge variant="primary">
            {{ props.row.subject.exam }}%
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'subject.coefficient'">
          <b-badge variant="warning">
            {{ props.row.subject.coefficient }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'action'">
          <span>
            <router-link
              class="text-warning"
              :to="{
                name: 'teaching-unit-edit',
                params: { id: props.row.id }
              }"
            >
              <feather-icon
                :id="`invoice-row-${props.row.id}-edit-icon`"
                icon="EditIcon"
                class="cursor-pointer mr-1"
                size="16"
              />
            </router-link>

            <b-tooltip
              title="Modifier"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            />

            <feather-icon
              :id="`invoice-row-${props.row.id}-trash-icon`"
              icon="TrashIcon"
              class="text-danger cursor-pointer"
              size="16"
              @click="deleteSemaine(props.row.id)"
            />
            <b-tooltip
              title="Supprimer"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-trash-icon`"
            />

          </span>
        </span>

        <!-- Column: Common -->
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="(value) => props.perPageChanged({ currentPerPage: value })"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <h4
      style="color:#0020d1;"
      class="mt-2"
    >
      Consultation des notes :
    </h4>
    <div class="d-flex ">
      <b-col
        md="4"
        class="mt-2"
      />
      <b-col
        md="3"
        class="mt-2"
      >
        <b-form-group>
          <b-button
            variant="gradient-success"
            class="ml-1 d-inline-block"
            @click.prevent="addNote"
          >
            <feather-icon icon="CheckCircleIcon" />
            <span class="ml-1">Validation Notes</span>
          </b-button>
        </b-form-group>
      </b-col>
    </div>
    <vue-good-table
      :columns="columnss"
      :rows="notes"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'username'"
          class="text-nowrap"
        >
          <b-avatar
            src="/assets/images/avatars/user.png"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.username }}</span>
        </div>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'calcule_moy_matiere'">
          <b-badge variant="success">
            {{ props.row.calcule_moy_matiere }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'calcul_moy_unite'">
          <b-badge variant="warning">
            {{ props.row.calcul_moy_unite }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'action'">
          <span>
            <router-link
              class="text-warning"
              :to="{
                name: 'teaching-unit-edit',
                params: { id: props.row.id }
              }"
            >
              <feather-icon
                :id="`invoice-row-${props.row.id}-edit-icon`"
                icon="EditIcon"
                class="cursor-pointer mr-1"
                size="16"
              />
            </router-link>

            <b-tooltip
              title="Modifier"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            />

            <feather-icon
              :id="`invoice-row-${props.row.id}-trash-icon`"
              icon="TrashIcon"
              class="text-danger cursor-pointer"
              size="16"
              @click="deleteTeachingUnit(props.row.id)"
            />
            <b-tooltip
              title="Supprimer"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-trash-icon`"
            />

          </span>
          <span>
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <b-form-input
                  v-model="props.row.note"
                  placeholder="Examen"
                  @change="setNotes(props.row.id, props.row.student.id, props.row.group.id, props.row.subject.id ,props.row.subject.unity.id, props.row.calcul_moy_unite ,props.row.semester, props.row.note )"
                />
              </div>
            </div>

          </span>

        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->

    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormSelect, BButton, BTooltip, BCol, BBadge, BFormInput,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import storeAuth from '@/store/store'
import vSelect from 'vue-select'

// import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormSelect,
    BButton,
    BTooltip,
    BCol,
    vSelect,
    BBadge,
    BFormInput,
  },
  data() {
    return {
      load: 'false',
      pageLength: 10,
      user: {},
      academicYear: {},
      academicYears: [],
      semester: '',
      semesters: ['semester 1', 'semester 2'],
      levels: [1, 2, 3, 4, 5, 6, 7, 8],
      columns: [
        {
          label: 'Semestre',
          field: 'semester',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Unité',
          field: 'subject.unity.label',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Matière',
          field: 'subject.label',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Coef',
          field: 'subject.coefficient',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher type',
          },
        },
        {
          label: 'Crédit',
          field: 'subject.credit',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'Evaluation',
          field: 'subject.exam_mode',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'Assiduité',
          field: 'subject.assiduity',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'DS',
          field: 'subject.ds',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'Examen',
          field: 'subject.exam',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
      ],
      columnss: [
        {
          label: 'N°',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Identité',
          field: 'student.id',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Nom & Prénom',
          field: 'student.username',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher date',
          },
        },
        {
          label: 'Assiduité',
          field: 'assiduite_note',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Absence DS',
          field: 'ds_presence',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'DS',
          field: 'ds_note',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'Contrôle Continu',
          field: 'note_cc',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'Présence Examen',
          field: 'exam_presence',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher type',
          },
        },
        {
          label: 'Examen',
          field: 'exam_note',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'Moyenne',
          field: 'calcule_moy_matiere',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'Moyenne Unité',
          field: 'calcul_moy_unite',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'Crédit Matière',
          field: 'subject.credit',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'Crédit Unité',
          field: 'subject.unity.total_credit',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher type',
          },
        },
        {
          label: 'Unité Validée',
          field: 'subject.unity.is_active',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'Date création',
          field: 'created_at',
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher date',
          },
        },
        {
          label: 'Date Modif',
          field: 'updated_at',
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher date',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },

      ],
      paiements: [],
      inscriptions: [],
      students: [],
      discounts: [],
      notes: [],
      parcour: {},
      searchTerm: '',
      currentAcademicYear: {},
      semester1: [],
      semester2: [],
      groups: [],
      group: {},
      subjects: [],
      subjectt: [],
      subject: {},
      noteStudents: [],
      groupId: '',
      semesterNote: [],
      subject_groups: [],
      teachers: [],
      semesterNoteCaractere: '',

    }
  },
  computed: {
    active() {
      const statusColor = {
        true: 'light-success',
        false: 'light-danger',
      }

      return status => statusColor[status]
    },
    SubjectGroups() {
      return this.subjects.filter(subject => subject.parcour.id === this.groupId)
    },
    notezero() {
      return [this.notes[0]]
      // this.testSubject()
      // return this.semesterNote
    },

  },
  created() {
    this.getGroups()
    this.getAcademicYears()
    this.getSubjects()
    this.user = storeAuth.state.user
    this.student_id = this.$route.params.id
    this.currentAcademicYear = storeAuth.state.currentAcademicYear
    this.academicYear = storeAuth.state.currentAcademicYear
  },
  methods: {
    deleteSemester() {
      this.semester = ''
    },
    async getListeNotes() {
      if (this.semester === 1) {
        this.semesterNoteCaractere = 'semester 1'
      }
      if (this.semester === 2) {
        this.semesterNoteCaractere = 'semester 2'
      }
      const response = await axios.get(`/api/notes/?group_id=${this.group.id}&subject_id=${this.subject.subject_id}&academic_year_id=${this.currentAcademicYear.id}&semester=${this.semester}`)
      this.notes = response.data
    },
    testSubject() {
      this.semesterNote.push(this.notes[0])
    },
    setNotes(noteId, id, groupId, subjectId, unityId, moyenneUnity, semester) {
      const year = this.currentAcademicYear.id
      const item = {
        noteId,
        id,
        groupId,
        unityId,
        subjectId,
        moyenneUnity,
        semester,
        year,
      }
      // console.log(item)
      const check = this.noteStudents.find(student => student.id === item.id)
      const index = this.noteStudents.findIndex(student => student.id === item.id)
      if (check === undefined) {
        this.noteStudents.push(item)
      } else {
        this.noteStudents.splice(index, 1, item)
      }
    },

    async addNote() {
      const tableNotes = []

      this.notes.map(item => tableNotes.push({
        noteId: item.id,
        id: item.student.id,
        groupId: item.group.id,
        subjectId: item.subject.id,
        unityId: item.subject.unity.id,
        moyenneUnity: item.calcul_moy_unite,
        semester: item.semester,
        year: this.currentAcademicYear.id,
      }))
      try {
        await axios
          .post(
            '/api/deliberations/add-list-note/',
            {
              notes: tableNotes,
            },
          )
        this.$router.push('/note-valider')
        setTimeout(() => {
          this.showToast('success', 'top-center', 'Note ajouté avec succés')
        }, 1000)
      } catch (err) {
        setTimeout(() => {
          this.showToast('danger', 'top-center', err.toString())
        }, 1000)
      }
    },
    pushing(item) {
      const tableNotes = []
      tableNotes.push(item)
      // tableNotes.push(item)
      // tableNotes.push[{
      //   id: item.id, studentId: item.student.id, groupeId: item.group.id, subjectId: item.subject.id, unityId: item.subject.unity.id, moyenneUnity: item.calcule_moy_unite, semester: item.semester, year: this.currentAcademicYear.id,
      // }]
    },

    async getAcademicYears() {
      const response = await axios.get('api/academic-years/')
      this.academicYears = response.data
    },
    async getGroups() {
      const response = await axios.get('/api/groups/')
      this.groups = response.data
    },
    async getSubjects() {
      const response = await axios.get('/api/subjects/')
      this.subjects = response.data
    },
    async getNotes() {
      // to set Subject object null
      this.subject = {}
      this.groupId = this.group.id
      this.load = 'true'
      const response = await axios.get(`api/subjects/list_subject_group/?id=${this.groupId}`)

      this.subject_groups = response.data[0].distributionsubjectsgroup_set
      this.load = 'false'
    },

    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(
        2,
        '0',
      )} | ${h}`
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
